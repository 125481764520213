.ShimmerText {
  color: var(--white-70);
  font-weight: 600;
  white-space: nowrap;

  transition: color var(--transition-ease);
}

.ShimmerText--emphasis {
  color: var(--white-90);
}

.ShimmerText:hover {
  color: var(--white-90);
}

/* .ShimmerText:hover .ShimmerText-character {
  color: var(--white-70);
  animation-name: opacity-pulse;
  animation-timing-function: ease;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes opacity-pulse {
  0%   {color: var(--white-70)}
  50%  {color: var(--white-90)}
  100% {color: var(--white-70)}
} */

.ShimmerText-icon {
  position: relative;
  top: 1px;
}