.Visualization {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Visualization-rotatableGroup {
  transform-origin: center;
}

.Visualization.is-transitionRotation .Visualization-rotatableGroup {
  transition: transform var(--transition-ease);
}

.Visualization-overlay {
  pointer-events: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--black-100);
  opacity: 1;

  transition: opacity var(--transition-snappy-out); 
}

.Visualization.is-visible .Visualization-overlay {
  opacity: 0;
}

.Visualization-corner {
  pointer-events: none;
}

.Visualization-corner--invisible {
  cursor: pointer;
}

.Visualization-hoverTarget {
  pointer-events: none;
}