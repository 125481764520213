.Footer {
  position: fixed;
  bottom: 0px;
  left: 0px;

  padding: 20px 40px 50px 40px;
  max-width: min(400px, 100vw);
}

.Footer-text {
  cursor: default;
  margin: 0;
  margin-bottom: 16px;
}

.Footer-textEmphasis {
  font-weight: 500;
  color: var(--white-85);
}