.Shader {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

canvas {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}