:root {
  --white-60: rgba(255, 255, 255, 0.6);
  --white-70: #B2B2B2;
  --white-85: rgba(255, 255, 255, 0.85);
  --white-90: rgba(255, 255, 255, 1);

  --black-100: #000000;

  --transition-cubic: 0.55s cubic-bezier(0.19, 1, 0.22, 1);
  --transition-ease: 0.25s ease;
  --transition-snappy-out: 0.25s cubic-bezier(0.19, 1, 0.22, 1);

  --button-input-padding: 4px 0px;
  --horizontal-margin: 40px;
  --vertical-margin: 50px;
  --max-page-content-width: calc(100vw - 2 * var(--horizontal-margin));
}

body {
  margin: 0;
  background-color: #000000;

  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: var(--white-60);
}

button {
  line-height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;

  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: var(--white-60);

  padding: var(--button-input-padding);
}

input {
  height: 20px;
  line-height: 20px;
  border: none;
  outline: none;
  background-color: transparent;

  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: var(--white-85);

  padding: var(--button-input-padding);
}

input::placeholder {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: var(--white-60);
}
