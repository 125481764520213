/* Wrapper */

.ButtonToInput {
  position: relative;
  display: inline-block;
}

.ButtonToInput.is-showingSuccess {
  width: min(400px, var(--max-page-content-width));
}

.ButtonToInput.is-error {
  animation-name: shake;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

@keyframes shake {
  0% {transform: translateX(0px)}
  20% {transform: translateX(9px)}
  40% {transform: translateX(-9px)}
  60% {transform: translateX(6px)}
  80% {transform: translateX(-6px)}
  100% {transform: translateX(0px)}
}


/* Button */

.ButtonToInput-button {
  font-weight: 600;
  opacity: 1;
  visibility: visible;
  transition: color var(--transition-ease), opacity var(--transition-ease), visibility var(--transition-ease);
}

.ButtonToInput-button:hover, 
.ButtonToInput-button:focus {
  color: var(--white-85);
}

.ButtonToInput:not(.is-showingButton) .ButtonToInput-button {
  opacity: 0;
  visibility: hidden;
}


/* Input */

.ButtonToInput-inputWrapper {
  width: min(400px, var(--max-page-content-width));
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 8px;
  transition: width var(--transition-cubic);
}

.ButtonToInput.is-showingButton .ButtonToInput-inputWrapper {
  pointer-events: none;
  width: 100%;
}

.ButtonToInput-inputSubmit {
  margin: 0px -4px;
  padding: 4px 4px;
  opacity: 1;
  visibility: visible;
  transition: right var(--transition-cubic), color var(--transition-ease), opacity var(--transition-cubic) 0.1s, visibility var(--transition-cubic) 0.1s;
}

.ButtonToInput-inputSubmit:hover, .ButtonToInput-inputSubmit:focus {
  color: var(--white-85);
}

.ButtonToInput:not(.is-showingInput) .ButtonToInput-inputSubmit {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.ButtonToInput-input {
  width: 100%;

  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition-ease) 0.25s, visibility var(--transition-ease) 0.25s;
}

.ButtonToInput:not(.is-showingInput) .ButtonToInput-input {
  opacity: 0;
  display: hidden;
  pointer-events: none;
}


/* Success Text */

.ButtonToInput-successText {
  position: absolute;
  top: 0;
  width: 100%;

  margin: 0px;
  padding: var(--button-input-padding);

  transition: opacity var(--transition-ease) 0.25s, visibility var(--transition-ease) 0.25s;
}

.ButtonToInput:not(.is-showingSuccess) .ButtonToInput-successText {
  opacity: 0;
  visibility: hidden;
}


/* Underline */

.ButtonToInput-underline {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid var(--white-60);
  position: absolute;

  transition: border-bottom var(--transition-ease), width var(--transition-cubic);
}

.ButtonToInput.is-showingButton:has(.ButtonToInput-button:hover) .ButtonToInput-underline, 
.ButtonToInput.is-showingButton:has(.ButtonToInput-button:focus) .ButtonToInput-underline {
  border-bottom: 1px solid var(--white-85);
}

.ButtonToInput.is-showingInput .ButtonToInput-underline {
  width: min(400px, var(--max-page-content-width));
}

.ButtonToInput.is-showingSuccess .ButtonToInput-underline {
  width: 0px;
  right: 0;
}

